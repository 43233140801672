import React, {useRef} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import styled from 'styled-components'
import PageHeading from './page-heading'
import Container from './container'
import TextBlock from './text-block'
import Fade from 'react-reveal/Fade'

export default function EDesignForm({cssModifier, id, selectedPackageLevel, setSelectedPackageLevel}) {
  const firstNameInputRef = useRef(null)
  const prevSelectedPackageLevelRef = useRef(null)

  const data = useStaticQuery(graphql`
    {
      prismicEDesignPage {
        data {
          form_intro_text {
            html
          }
        }
      }
    }
  `)

  const prismicData = {
    formIntroText: data.prismicEDesignPage.data.form_intro_text?.html,
  }

  return (
    <StyledComponent id={id} className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading="E-Design Form" />
      <Container type="wide">
        {prismicData.formIntroText && (
          <div className="row">
            <div className="intro">
              <div className="intro-text">
                <TextBlock>
                  <div className="form-intro-text" dangerouslySetInnerHTML={{__html: prismicData.formIntroText}} />
                </TextBlock>
              </div>
            </div>
          </div>
        )}
        <StyledForm
          className="form--e-design"
          name="Ali Hearn Website E-Design Form"
          method="POST"
          action="/e-design-thanks/"
          enctype="multipart/form-data"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input name="form-name" type="hidden" value="Ali Hearn Website E-Design Form" />
          <input type="hidden" name="bot-field" />
          <div className="form-contents">
            <div className="form-row form-row--package-option">
              <div className="form-item form-item--package-option">
                <label className="form-item__label" htmlFor="package-option">
                  E-Design package option *
                </label>
                <div className="select-wrapper">
                  <select
                    id="package-option"
                    name="package-option"
                    className="form-item__select"
                    autoComplete="off"
                    value={selectedPackageLevel}
                    onChange={e => setSelectedPackageLevel(e.target.value)}
                    required
                  >
                    <option value="" disabled></option>
                    <option value="Premium">Premium package</option>
                    <option value="Advanced">Advanced package</option>
                    <option value="Classic">Classic package</option>
                  </select>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.439453 1.49825L1.56057 0.501709L9.00001 8.87108L16.4395 0.501709L17.5606 1.49825L9.00001 11.1289L0.439453 1.49825Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <h3>Your details</h3>
            <div className="form-row form-row--name">
              <div className="form-item form-item--first-name">
                <label className="form-item__label" htmlFor="first-name">
                  First name *
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="first-name"
                  name="first-name"
                  autoComplete="given-name"
                  ref={firstNameInputRef}
                  required
                />
              </div>
              <div className="form-item form-item--last-name">
                <label className="form-item__label" htmlFor="last-name">
                  Last name *
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="last-name"
                  name="last-name"
                  autoComplete="family-name"
                  required
                />
              </div>
            </div>
            <div className="form-row form-row--email">
              <div className="form-item form-item--email">
                <label className="form-item__label" htmlFor="email">
                  Email *
                </label>
                <input className="form-item__input" type="email" id="email" name="email" autoComplete="email" required />
              </div>
            </div>
            <h3>Project address</h3>
            <div className="form-row form-row--country">
              <div className="form-item form-item--country">
                <label className="form-item__label" htmlFor="country">
                  Country
                </label>
                <div className="select-wrapper">
                  <select
                    id="country"
                    name="country"
                    className="form-item__select"
                    autoComplete="country-name"
                    defaultValue="United Kingdom"
                  >
                    <option value="Afghanistan">Afghanistan</option>
                    <option value="Åland Islands">Åland Islands</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antarctica">Antarctica</option>
                    <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                    <option value="Botswana">Botswana</option>
                    <option value="Bouvet Island">Bouvet Island</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                    <option value="Brunei Darussalam">Brunei Darussalam</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">Central African Republic</option>
                    <option value="Chad">Chad</option>
                    <option value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote D'ivoire">Cote D'ivoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">Dominican Republic</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Territories">French Southern Territories</option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guernsey">Guernsey</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guinea-bissau">Guinea-bissau</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                    <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="India">India</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jersey">Jersey</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                    <option value="Korea, Republic of">Korea, Republic of</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macao">Macao</option>
                    <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                    <option value="Moldova, Republic of">Moldova, Republic of</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Namibia">Namibia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Netherlands Antilles">Netherlands Antilles</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau">Palau</option>
                    <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Philippines">Philippines</option>
                    <option value="Pitcairn">Pitcairn</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russian Federation">Russian Federation</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="Saint Helena">Saint Helena</option>
                    <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                    <option value="Saint Lucia">Saint Lucia</option>
                    <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                    <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                    <option value="Samoa">Samoa</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="South Georgia and The South Sandwich Islands">
                      South Georgia and The South Sandwich Islands
                    </option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Timor-leste">Timor-leste</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Emirates">United Arab Emirates</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="United States">United States</option>
                    <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                    <option value="Uruguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Viet Nam">Viet Nam</option>
                    <option value="Virgin Islands, British">Virgin Islands, British</option>
                    <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                    <option value="Wallis and Futuna">Wallis and Futuna</option>
                    <option value="Western Sahara">Western Sahara</option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0.439453 1.49825L1.56057 0.501709L9.00001 8.87108L16.4395 0.501709L17.5606 1.49825L9.00001 11.1289L0.439453 1.49825Z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
            <div className="form-row form-row--address-line1">
              <div className="form-item form-item--address-line1">
                <label className="form-item__label" htmlFor="address-line1">
                  Address Line 1
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="address-line1"
                  name="address-line1"
                  autoComplete="address-line1"
                />
              </div>
            </div>
            <div className="form-row form-row--address-line2">
              <div className="form-item form-item--address-line2">
                <label className="form-item__label" htmlFor="address-line2">
                  Address Line 2
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="address-line2"
                  name="address-line2"
                  autoComplete="address-line2"
                />
              </div>
            </div>
            <div className="form-row form-row--city-town">
              <div className="form-item form-item--city-town">
                <label className="form-item__label" htmlFor="city-town">
                  City / Town
                </label>
                <input className="form-item__input" type="text" id="city-town" name="city-town" autoComplete="address-level2" />
              </div>
            </div>
            <div className="form-row form-row--county-region">
              <div className="form-item form-item--county-region">
                <label className="form-item__label" htmlFor="county-region">
                  County / Region
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="county-region"
                  name="county-region"
                  autoComplete="address-level1"
                />
              </div>
            </div>
            <div className="form-row form-row--postcode">
              <div className="form-item form-item--postcode">
                <label className="form-item__label" htmlFor="postcode">
                  Postcode
                </label>
                <input className="form-item__input" type="text" id="postcode" name="postcode" autoComplete="postal-code" />
              </div>
            </div>
            <h3>Project details</h3>
            <fieldset>
              <legend>Type of project</legend>

              <div className="checkbox-columns">
                <div className="form-item form-item--checkbox-pair form-item--new-build">
                  <input className="form-item__input" type="checkbox" id="new-build" name="new-build" value="Selected" />
                  <label className="form-item__label" htmlFor="new-build">
                    New Build
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--whole-house">
                  <input className="form-item__input" type="checkbox" id="whole-house" name="whole-house" value="Selected" />
                  <label className="form-item__label" htmlFor="whole-house">
                    Whole House
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--complete-upstairs">
                  <input
                    className="form-item__input"
                    type="checkbox"
                    id="complete-upstairs"
                    name="complete-upstairs"
                    value="Selected"
                  />
                  <label className="form-item__label" htmlFor="complete-upstairs">
                    Complete Upstairs
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--complete-downstairs">
                  <input
                    className="form-item__input"
                    type="checkbox"
                    id="complete-downstairs"
                    name="complete-downstairs"
                    value="Selected"
                  />
                  <label className="form-item__label" htmlFor="complete-downstairs">
                    Complete Downstairs
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--bedroom">
                  <input className="form-item__input" type="checkbox" id="bedroom" name="bedroom" value="Selected" />
                  <label className="form-item__label" htmlFor="bedroom">
                    Bedroom(s)
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--bathroom">
                  <input className="form-item__input" type="checkbox" id="bathroom" name="bathroom" value="Selected" />
                  <label className="form-item__label" htmlFor="bathroom">
                    Bathroom(s)
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--kitchen">
                  <input className="form-item__input" type="checkbox" id="kitchen" name="kitchen" value="Selected" />
                  <label className="form-item__label" htmlFor="kitchen">
                    Kitchen
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--open-plan-kitchen-family-room">
                  <input
                    className="form-item__input"
                    type="checkbox"
                    id="open-plan-kitchen-family-room"
                    name="open-plan-kitchen-family-room"
                    value="Selected"
                  />
                  <label className="form-item__label" htmlFor="open-plan-kitchen-family-room">
                    Open Plan Kitchen Family Room
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--living-room">
                  <input className="form-item__input" type="checkbox" id="living-room" name="living-room" value="Selected" />
                  <label className="form-item__label" htmlFor="living-room">
                    Living Room
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--snug">
                  <input className="form-item__input" type="checkbox" id="snug" name="snug" value="Selected" />
                  <label className="form-item__label" htmlFor="snug">
                    Snug
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--dining-room">
                  <input className="form-item__input" type="checkbox" id="dining-room" name="dining-room" value="Selected" />
                  <label className="form-item__label" htmlFor="dining-room">
                    Dining Room
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--hallway">
                  <input className="form-item__input" type="checkbox" id="hallway" name="hallway" value="Selected" />
                  <label className="form-item__label" htmlFor="hallway">
                    Hallway
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--landing">
                  <input className="form-item__input" type="checkbox" id="landing" name="landing" value="Selected" />
                  <label className="form-item__label" htmlFor="landing">
                    Landing
                  </label>
                </div>

                <div className="form-item form-item--checkbox-pair form-item--utility-or-boot-room">
                  <input
                    className="form-item__input"
                    type="checkbox"
                    id="utility-or-boot-room"
                    name="utility-or-boot-room"
                    value="Selected"
                  />
                  <label className="form-item__label" htmlFor="utility-or-boot-room">
                    Utility or Boot Room
                  </label>
                </div>
                <div className="form-item form-item--checkbox-pair form-item--other">
                  <input className="form-item__input" type="checkbox" id="other" name="other" value="Selected" />
                  <label className="form-item__label" htmlFor="other">
                    Other (please specify below)
                  </label>
                </div>
              </div>
            </fieldset>
            <div className="form-row form-row--other-project-type">
              <div className="form-item form-item--other-project-type">
                <label className="form-item__label sr-only" htmlFor="other-project-type">
                  Other project type
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="other-project-type"
                  name="other-project-type"
                  autoComplete="off"
                  placeholder="Other"
                />
              </div>
            </div>
            <div className="form-row form-row--when-are-you-ideally-wanting-to-start-work-on-the-project">
              <div className="form-item form-item--when-are-you-ideally-wanting-to-start-work-on-the-project">
                <label className="form-item__label" htmlFor="when-are-you-ideally-wanting-to-start-work-on-the-project">
                  When are you ideally wanting to start work on the project?
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="when-are-you-ideally-wanting-to-start-work-on-the-project"
                  name="when-are-you-ideally-wanting-to-start-work-on-the-project"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-row max-line-width">
              <p>
                We require floorplans to start designs, please check your document format and upload here - max total upload 8mb.
              </p>
            </div>
            <div className="form-row form-row--input-type-file form-row--pdf-scaled-plans">
              <div className="form-item form-item--input-type-file form-item--pdf-scaled-plans-1">
                <label className="form-item__label" htmlFor="pdf-scaled-plans-1">
                  PDF / Scaled plans
                </label>
                <input
                  className="form-item__input"
                  type="file"
                  id="pdf-scaled-plans-1"
                  name="pdf-scaled-plans-1"
                  accept="image/*,.pdf"
                />
              </div>
              <div className="form-item form-item--checkbox-pair form-item--input-type-file-add-more-checkbox">
                <input
                  className="form-item__input"
                  type="checkbox"
                  id="add-more-checkbox-pdf-scaled-plans"
                  name="add-more-checkbox-pdf-scaled-plans"
                  value="Selected"
                />
                <label className="form-item__label" htmlFor="add-more-checkbox-pdf-scaled-plans">
                  Add a second plan
                </label>
              </div>
              <div className="form-item form-item--input-type-file form-item--pdf-scaled-plans-2">
                <label className="form-item__label sr-only" htmlFor="pdf-scaled-plans-2">
                  PDF / Scaled plan 2
                </label>
                <input
                  className="form-item__input"
                  type="file"
                  id="pdf-scaled-plans-2"
                  name="pdf-scaled-plans-2"
                  accept="image/*,.pdf"
                />
              </div>
            </div>
            <div className="form-row form-row--input-type-file form-row--architects-dwg-files">
              <div className="form-item form-item--input-type-file form-item--architects-dwg-files-1">
                <label className="form-item__label" htmlFor="architects-dwg-files-1">
                  Architects DWG files
                </label>
                <input
                  className="form-item__input"
                  type="file"
                  id="architects-dwg-files-1"
                  name="architects-dwg-files-1"
                  accept="image/x-dwg,.pdf"
                />
              </div>
              <div className="form-item form-item--checkbox-pair form-item--input-type-file-add-more-checkbox">
                <input
                  className="form-item__input"
                  type="checkbox"
                  id="add-more-checkbox-architects-dwg-files"
                  name="add-more-checkbox-architects-dwg-files"
                  value="Selected"
                />
                <label className="form-item__label" htmlFor="add-more-checkbox-architects-dwg-files">
                  Add a second DWG file
                </label>
              </div>
              <div className="form-item form-item--input-type-file form-item--architects-dwg-files-2">
                <label className="form-item__label sr-only" htmlFor="architects-dwg-files-2">
                  Architects DWG file 2
                </label>
                <input
                  className="form-item__input"
                  type="file"
                  id="architects-dwg-files-2"
                  name="architects-dwg-files-2"
                  accept="image/x-dwg,.pdf"
                />
              </div>
            </div>
            <div className="form-row form-row--input-type-file form-row--sketches">
              <div className="form-item form-item--input-type-file form-item--sketches-1">
                <label className="form-item__label" htmlFor="sketches-1">
                  Your own sketches
                </label>
                <input className="form-item__input" type="file" id="sketches-1" name="sketches-1" accept="image/*,.pdf" />
              </div>
              <div className="form-item form-item--checkbox-pair form-item--input-type-file-add-more-checkbox">
                <input
                  className="form-item__input"
                  type="checkbox"
                  id="add-more-checkbox-sketches"
                  name="add-more-checkbox-sketches"
                  value="Selected"
                />
                <label className="form-item__label" htmlFor="add-more-checkbox-sketches">
                  Add a second sketch
                </label>
              </div>
              <div className="form-item form-item--input-type-file form-item--sketches-2">
                <label className="form-item__label sr-only" htmlFor="sketches-2">
                  Your own sketch 2
                </label>
                <input className="form-item__input" type="file" id="sketches-2" name="sketches-2" accept="image/*,.pdf" />
              </div>
            </div>
            <div className="form-row max-line-width">
              <p>
                Please note that for PDF / scaled plans, architects drawings and your own sketches must include dimensions, window
                & door positions, window & door sizes, ceiling height, window sill heights and any steel positions. We do not take
                any responsibility for incorrect measurements, and will work to what you provide us with.
              </p>
            </div>
            <div className="form-row form-row--your-budget-for-room">
              <div className="form-item form-item--your-budget-for-room">
                <label className="form-item__label" htmlFor="your-budget-for-room">
                  Your Budget For Room(s)
                  <br />
                  <span className="font-weight-normal">Our fee not included</span>
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="your-budget-for-room"
                  name="your-budget-for-room"
                  inputmode="numeric"
                  autoComplete="off"
                  placeholder="£"
                />
              </div>
            </div>
            <div className="form-row form-row--what-style-of-design-do-you-favour">
              <div className="form-item form-item--what-style-of-design-do-you-favour">
                <label className="form-item__label" htmlFor="what-style-of-design-do-you-favour">
                  What style of design do you favour?
                  <br />
                  <span className="font-weight-normal">Please add any links to Pinterest / Instagram etc</span>
                </label>
                <textarea
                  className="form-item__textarea"
                  id="what-style-of-design-do-you-favour"
                  name="what-style-of-design-do-you-favour"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-row form-row--what-are-your-design-dislikes">
              <div className="form-item form-item--what-are-your-design-dislikes">
                <label className="form-item__label" htmlFor="what-are-your-design-dislikes">
                  What are your design dislikes?
                </label>
                <textarea
                  className="form-item__textarea"
                  id="what-are-your-design-dislikes"
                  name="what-are-your-design-dislikes"
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="form-row form-row--is-there-anything-else-youd-like-to-tell-us-about-your-project">
              <div className="form-item form-item--is-there-anything-else-youd-like-to-tell-us-about-your-project">
                <label className="form-item__label" htmlFor="is-there-anything-else-youd-like-to-tell-us-about-your-project">
                  Is there anything else you&rsquo;d like to tell us about your project?
                </label>
                <textarea
                  className="form-item__textarea"
                  id="is-there-anything-else-youd-like-to-tell-us-about-your-project"
                  name="is-there-anything-else-youd-like-to-tell-us-about-your-project"
                  autoComplete="off"
                />
              </div>
            </div>

            <fieldset className="form-fieldset--share-on-social-media">
              <legend>Are you happy for us to share your project on our social media? *</legend>
              <div className="form-item form-item--radio-pair form-item--share-on-social-media form-item--share-on-social-media-netlify-fieldset-label">
                <input
                  className="form-item__input"
                  type="radio"
                  id="share-on-social-media-netlify-fieldset-label"
                  name="share-on-social-media"
                  value="Netlify fieldset label"
                  required
                />
                <label className="form-item__label" htmlFor="share-on-social-media-netlify-fieldset-label">
                  Are you happy for us to share your project on our social media? *
                </label>
              </div>
              <div className="form-item form-item--radio-pair form-item--share-on-social-media">
                <input
                  className="form-item__input"
                  type="radio"
                  id="share-on-social-media-anonymously"
                  name="share-on-social-media"
                  value="Anonymously"
                />
                <label className="form-item__label" htmlFor="share-on-social-media-anonymously">
                  Anonymously
                </label>
              </div>
              <div className="form-item form-item--checkbox-pair form-item--share-on-social-media">
                <input
                  className="form-item__input"
                  type="radio"
                  id="share-on-social-media-no"
                  name="share-on-social-media"
                  value="No"
                />
                <label className="form-item__label" htmlFor="share-on-social-media-no">
                  No
                </label>
              </div>
            </fieldset>
          </div>
          <div className="form-row form-row--submit">
            <p className="file-size-warning">
              <strong>
                <span className="note">NOTE: </span>The total upload file size must be less than 8 MB
              </strong>
            </p>
          </div>
          <div className="form-row form-row--submit">
            <button type="submit" className="btn btn--primary">
              Send
            </button>
          </div>
        </StyledForm>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 4rem;
  scroll-margin: 88px;

  @media (min-width: 375px) {
    scroll-margin: 94px;
  }

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  .intro {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 850px) {
      flex-direction: row;
    }
  }
`
const StyledForm = styled.form`
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3rem;
  max-width: 90rem;

  &:has(input[type='checkbox']#other:not(:checked)) {
    .form-row--other-project-type {
      display: none;
    }
  }
  &:has(input[type='checkbox']#other:checked) {
    .form-row--other-project-type {
      display: flex;
    }
  }

  h3 {
    margin: 0 0 2rem 0;
  }

  input[type='file'] {
    cursor: pointer;
    padding: 0.5rem 1.8rem;
    border-top: 0;
    border-bottom: 0;

    &:focus {
      outline: none;
    }

    &:focus-visible {
      outline: 1px solid black;
      outline-offset: 2px;
    }
  }

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  legend {
    border: 0;
    display: block;
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0 0 1.5rem 0;
    padding: 0;
  }

  .select-wrapper {
    position: relative;

    svg {
      pointer-events: none;
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .form-item {
    @media (min-width: 800px) {
      width: 100%;
    }

    &--other-project-type {
      width: 100%;
    }

    &--share-on-social-media-netlify-fieldset-label {
      display: none !important;
    }

    &--input-type-file-add-more-checkbox {
      padding: 1rem 1.8rem;
      margin-bottom: 0 !important;
    }

    &--checkbox-pair,
    &--radio-pair {
      align-items: center;
      display: flex;
      gap: 1rem;
      margin-bottom: 1rem;

      input[type='checkbox'],
      input[type='radio'] {
        cursor: pointer;
        height: 1.5rem;
        margin: 0;
        padding: 0;
        width: 1.5rem;

        &:focus {
          outline: none;
        }

        &:focus-visible {
          outline: 1px solid black;
          outline-offset: 2px;
        }
      }

      label {
        cursor: pointer;
        font-weight: 300;
        margin: 0;
      }
    }

    &--first-name {
      margin-bottom: 3rem;
      @media (min-width: 800px) {
        margin-right: 6rem;
        margin-bottom: 0;
      }
    }

    &__label {
      display: block;
      font-weight: 600;
      font-size: 1.4rem;
      margin-bottom: 0.8rem;

      span.font-weight-normal {
        font-weight: 300;
      }
    }

    &__input {
      border: 1px solid #e1e1e1;
      background-color: #ffffff;
      font-size: 1.4rem;

      &:focus {
        outline: 1px solid black;
        outline-offset: 2px;
      }
    }

    &__select {
      appearance: none;
      background-color: #ffffff;
      border: 1px solid #e1e1e1;
      cursor: pointer;
      display: block;
      font-size: 1.4rem;
      padding: 1.7rem 4rem 1.7rem 1.8rem;
      width: 100%;

      &:focus {
        outline: 1px solid black;
        outline-offset: 2px;
      }
    }

    &__textarea {
      background-color: #ffffff;
      border: 1px solid #e1e1e1;
      field-sizing: normal;
      font-size: 1.4rem;
      height: 11rem;
      max-height: 45rem;
      min-height: 11rem;
      resize: vertical;

      &:focus {
        outline: 1px solid black;
        outline-offset: 2px;
      }
    }
  }

  .form-row {
    max-width: 1300px;

    @media (min-width: 800px) {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &--other-project-type {
      margin-top: 0;

      @media (min-width: 600px) {
        margin-top: 1rem;
      }

      @media (min-width: 900px) {
        margin-top: 0;
      }
    }

    &--when-are-you-ideally-wanting-to-start-work-on-the-project {
      margin-top: 2rem;
    }

    &--input-type-file {
      flex-direction: column;

      &.form-row--pdf-scaled-plans {
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:not(:checked)) {
          .form-item--input-type-file {
            display: none;
          }
          .form-item--input-type-file:first-child {
            display: block;
          }
        }
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:checked) {
          .form-item--input-type-file {
            display: block;
          }
        }
      }

      &.form-row--architects-dwg-files {
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:not(:checked)) {
          .form-item--input-type-file {
            display: none;
          }
          .form-item--input-type-file:first-child {
            display: block;
          }
        }
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:checked) {
          .form-item--input-type-file {
            display: block;
          }
        }
      }

      &.form-row--sketches {
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:not(:checked)) {
          .form-item--input-type-file {
            display: none;
          }
          .form-item--input-type-file:first-child {
            display: block;
          }
        }
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:checked) {
          .form-item--input-type-file {
            display: block;
          }
        }
      }

      &.form-row--inspiration-images {
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:not(:checked)) {
          .form-item--input-type-file {
            display: none;
          }
          .form-item--input-type-file:first-child {
            display: block;
          }
        }
        &:has(.form-item--input-type-file-add-more-checkbox input[type='checkbox']:checked) {
          .form-item--input-type-file {
            display: block;
          }
        }
      }

      .form-item {
        &:first-child {
          input[type='file'] {
            border-top: 1px solid #e1e1e1;
            border-bottom: 1px solid #e1e1e1;
            padding-top: 1.7rem;
            padding-bottom: 1.7rem;
          }
        }
        &:nth-child(3) {
          input[type='file'] {
            border-top: 1px solid #e1e1e1;
            padding-top: 1.7rem;
          }
        }
        &:last-child {
          input[type='file'] {
            border-bottom: 1px solid #e1e1e1;
            padding-bottom: 1.7rem;
          }
        }
      }
    }

    &--submit {
      margin-top: 3rem;
      @media (min-width: 600px) {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }

      button {
        display: block;
        font-weight: 700;
        min-width: 11rem;
        width: 100%;

        @media (min-width: 600px) {
          width: auto;
        }
      }
    }
  }

  .file-size-warning {
    color: #777777;
    font-size: 1.2rem;
    font-weight: 600;

    .note {
      color: #000000;
    }
  }

  button[type='submit'] {
    height: 50px;
  }

  .checkbox-columns {
    columns: 1;

    @media (min-width: 600px) {
      columns: 2;
    }

    @media (min-width: 900px) {
      columns: 3;
      margin-bottom: 1rem;
    }
  }

  .max-line-width {
    max-width: 62rem;
  }
`
