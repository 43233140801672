import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Slider from 'react-slick'

export default function EDesignPackageContent({
  bodyCopyHtml,
  bulletPointsHtml,
  postBulletPointsHtml,
  cssModifier,
  ctaBtnClickHandler,
  ctaButtonLabel,
  headingText,
  packageLevel,
  price,
  primaryImage,
  quarternaryImage,
  secondaryImage,
  tertiaryImage,
}) {
  const slickSlidersettings = {
    autoplay: true,
    autoplaySpeed: 6000,
    dots: true,
    fade: true,
    infinite: true,
    lazyLoad: false,
    mobileFirst: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <StyledComponent className={`quote-block ${cssModifier ? `quote-block--${cssModifier}` : ''}`}>
      <Fade>
        <hr />
        <div className="wrapper">
          <div className="col col--1">
            {headingText && <h3 className="heading">{headingText}</h3>}
            {price && (
              <p className="price">
                <strong>{price}</strong>
              </p>
            )}
            {bodyCopyHtml && <div className="body-copy" dangerouslySetInnerHTML={{__html: bodyCopyHtml}} />}
            {bulletPointsHtml && <div className="bullet-points" dangerouslySetInnerHTML={{__html: bulletPointsHtml}} />}
            {postBulletPointsHtml && (
              <div className="post-bullet-points-text" dangerouslySetInnerHTML={{__html: postBulletPointsHtml}} />
            )}
            <button className="cta-button" type="button" onClick={() => ctaBtnClickHandler(packageLevel)}>
              {ctaButtonLabel}
            </button>
          </div>
          <div className="col col--2">
            <div className="photos">
              <Slider {...slickSlidersettings} className="mobile-photos">
                {primaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={primaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={primaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={primaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={primaryImage.thumbnails.small.url} />
                    <img
                      src={primaryImage.url}
                      alt={primaryImage.alt}
                      width={primaryImage.dimensions?.width}
                      height={primaryImage.dimensions?.height}
                    />
                  </picture>
                )}
                {secondaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={secondaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={secondaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={secondaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={secondaryImage.thumbnails.small.url} />
                    <img
                      src={secondaryImage.url}
                      alt={secondaryImage.alt}
                      width={secondaryImage.dimensions?.width}
                      height={secondaryImage.dimensions?.height}
                    />
                  </picture>
                )}
                {tertiaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={tertiaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={tertiaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={tertiaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={tertiaryImage.thumbnails.small.url} />
                    <img
                      src={tertiaryImage.url}
                      alt={tertiaryImage.alt}
                      width={tertiaryImage.dimensions?.width}
                      height={tertiaryImage.dimensions?.height}
                    />
                  </picture>
                )}
                {quarternaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={quarternaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={quarternaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={quarternaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={quarternaryImage.thumbnails.small.url} />
                    <img
                      src={quarternaryImage.url}
                      alt={quarternaryImage.alt}
                      width={quarternaryImage.dimensions?.width}
                      height={quarternaryImage.dimensions?.height}
                    />
                  </picture>
                )}
              </Slider>
              <div className="desktop-photos">
                {primaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={primaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={primaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={primaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={primaryImage.thumbnails.small.url} />
                    <img
                      src={primaryImage.url}
                      alt={primaryImage.alt}
                      width={primaryImage.dimensions?.width}
                      height={primaryImage.dimensions?.height}
                    />
                  </picture>
                )}
                {secondaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={secondaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={secondaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={secondaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={secondaryImage.thumbnails.small.url} />
                    <img
                      src={secondaryImage.url}
                      alt={secondaryImage.alt}
                      width={secondaryImage.dimensions?.width}
                      height={secondaryImage.dimensions?.height}
                    />
                  </picture>
                )}
                {tertiaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={tertiaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={tertiaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={tertiaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={tertiaryImage.thumbnails.small.url} />
                    <img
                      src={tertiaryImage.url}
                      alt={tertiaryImage.alt}
                      width={tertiaryImage.dimensions?.width}
                      height={tertiaryImage.dimensions?.height}
                    />
                  </picture>
                )}
                {quarternaryImage.url && (
                  <picture>
                    <source media="(min-width: 850px)" srcSet={quarternaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 750px)" srcSet={quarternaryImage.thumbnails.large.url} />
                    <source media="(min-width: 500px)" srcSet={quarternaryImage.thumbnails.medium.url} />
                    <source media="(min-width: 0px)" srcSet={quarternaryImage.thumbnails.small.url} />
                    <img
                      src={quarternaryImage.url}
                      alt={quarternaryImage.alt}
                      width={quarternaryImage.dimensions?.width}
                      height={quarternaryImage.dimensions?.height}
                    />
                  </picture>
                )}
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin: 6rem 0;

  .wrapper {
    margin-top: 6rem;
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 850px) {
      flex-direction: row-reverse;
      gap: 2rem;
    }
  }

  .col {
    &--1 {
      margin-bottom: 0;
      @media (min-width: 850px) {
        margin-bottom: 0;
        width: 60%;
      }
    }
    &--2 {
      margin-bottom: 3rem;
      @media (min-width: 850px) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 0;
        width: 40%;
      }
    }
  }

  .heading {
    font-size: 2.1rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 2rem 0;
    padding: 0 0 0 0;
    text-transform: uppercase;

    @media (min-width: 375px) {
      font-size: 2.3rem;
    }
  }

  .price {
    font-weight: 600;
    font-size: 1.4rem;
    margin: 0 0 2rem 0;

    strong {
      font-weight: inherit;
      font-size: inherit;
    }
  }

  .body-copy {
    margin: 0;
  }

  .bullet-points {
    margin: 2rem 0 0 0;
    width: 100%;

    ul {
      margin: 0;
      padding: 0 0 0 2rem;

      li {
        width: 100%;
      }
    }
  }

  .post-bullet-points-text {
    font-size: 1.1rem;
    margin: 2rem 0 0 0;
    width: 100%;
  }

  .cta-button {
    background-color: #000;
    border: 0;
    color: #fff;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 3rem 0 0 0;
    padding: 1rem 1rem;
    text-transform: uppercase;
  }

  .photos {
    // .slick-arrow {
    //   display: none !important;
    // }

    .mobile-photos {
      display: block;
      @media (min-width: 850px) {
        display: none;
      }
    }

    .desktop-photos {
      display: none;

      @media (min-width: 850px) {
        display: block;
      }

      picture {
        display: block;
        margin-bottom: 2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`
