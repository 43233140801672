import React, {useState} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import PageHeading from './page-heading'
import styled from 'styled-components'
import EDesignPackageContent from './e-design-package-content'
import TextBlock from './text-block'
import EDesignForm from './e-design-form'
import {E_DESIGN_FORM_SECTION_CSS_MODIFIER} from './../constants/global-constants'

export default function EDesignSection({cssModifier}) {
  const [selectedPackageLevel, setSelectedPackageLevel] = useState('')

  const data = useStaticQuery(graphql`
    {
      prismicEDesignPage {
        data {
          cta_button_label {
            text
          }
          intro_text {
            html
          }
          page_heading {
            text
          }
          packages {
            body_copy {
              html
            }
            bullet_points {
              html
            }
            post_bullet_points_text {
              html
            }
            heading {
              text
            }
            price {
              text
            }
            primary_image {
              url
              thumbnails {
                small {
                  url
                }
                medium {
                  url
                }
                large {
                  url
                }
              }
              alt
              dimensions {
                height
                width
              }
            }
            secondary_image {
              url
              thumbnails {
                small {
                  url
                }
                medium {
                  url
                }
                large {
                  url
                }
              }
              alt
              dimensions {
                height
                width
              }
            }
            tertiary_image {
              url
              thumbnails {
                small {
                  url
                }
                medium {
                  url
                }
                large {
                  url
                }
              }
              alt
              dimensions {
                height
                width
              }
            }
            quaternary_image {
              url
              thumbnails {
                small {
                  url
                }
                medium {
                  url
                }
                large {
                  url
                }
              }
              alt
              dimensions {
                height
                width
              }
            }
            package_level
          }
          post_enquiry_text {
            html
          }
          required_field_text {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicEDesignPage.data.page_heading.text,
    introText: data.prismicEDesignPage.data.intro_text?.html,
    ctaButtonLabel: data.prismicEDesignPage.data.cta_button_label?.text,
    packages: data.prismicEDesignPage.data.packages,
    postEnquiryText: data.prismicEDesignPage.data.post_enquiry_text.html,
    requiredFieldText: data.prismicEDesignPage.data.required_field_text.text,
  }

  function eDesignPackageSelected(packageLevel) {
    setSelectedPackageLevel(packageLevel)
    const eDesignFormElement = document.getElementById('e-design-form')
    let scrollOffsetTop
    if (window.innerWidth <= 375) {
      scrollOffsetTop = eDesignFormElement.offsetTop + 23
    } else {
      scrollOffsetTop = eDesignFormElement.offsetTop + 19
    }
    window.scrollTo({
      behavior: 'smooth',
      left: 0,
      top: scrollOffsetTop,
    })
  }

  return (
    <StyledComponent className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}>
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide">
        <div className="row">
          <div className="intro">
            <div className="intro-text">
              <TextBlock>
                <div
                  dangerouslySetInnerHTML={{
                    __html: prismicData.introText,
                  }}
                />
              </TextBlock>
            </div>
          </div>
          <div className="sections-wrapper">
            {prismicData.packages.map((item, index) => (
              <EDesignPackageContent
                bodyCopyHtml={item.body_copy.html}
                bulletPointsHtml={item.bullet_points.html}
                postBulletPointsHtml={item.post_bullet_points_text.html}
                cssModifier=""
                ctaBtnClickHandler={eDesignPackageSelected}
                ctaButtonLabel={prismicData.ctaButtonLabel}
                headingText={item.heading.text}
                key={index}
                packageLevel={item.package_level}
                price={item.price.text}
                primaryImage={item.primary_image}
                quarternaryImage={item.quaternary_image}
                secondaryImage={item.secondary_image}
                tertiaryImage={item.tertiary_image}
              />
            ))}
          </div>
        </div>
      </Container>
      <EDesignForm
        id="e-design-form"
        cssModifier={E_DESIGN_FORM_SECTION_CSS_MODIFIER}
        selectedPackageLevel={selectedPackageLevel}
        setSelectedPackageLevel={setSelectedPackageLevel}
      />
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;

  .row {
    margin: 0 auto;
    max-width: 90rem;
  }

  .intro {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (min-width: 850px) {
      flex-direction: row;
    }
  }

  .intro-image {
    margin-bottom: 6rem;

    @media (min-width: 850px) {
      width: 50%;
    }
  }

  .sections-wrapper {
    > div {
      &:first-child {
        margin: 2rem 0;
      }

      &:last-child {
        margin: 2rem 0 0 0;
      }
    }
  }
`
